import { Box, Flex, Icon, Image, Link, Text } from '@chakra-ui/react';
import { useLanguage } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';
import { Close } from '@veno-app/icons';
import React from 'react';

import { Card } from '../Card';
import type { UpdateBannerProps } from './types';

function UpdateBanner({
  mainImg,
  mainImgProps,
  text,
  secondaryText,
  link,
  isLinkExternal,
  backgroundSize,
  bgImage,
  onClose,
  linkText,
}: UpdateBannerProps) {
  const language = useLanguage();
  const smallText = language.lang === 'ko' || language.lang === 'it';

  return (
    <Card
      position="relative"
      h="124px"
      mx={0}
      px={4}
      py={2}
      bgPos="right"
      bgImage={bgImage}
      backgroundSize={backgroundSize}
      backgroundImage={bgImage}
      backgroundRepeat="no-repeat"
      flexDir="column"
      display="flex"
      _before={{
        roundedLeft: '5px',
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        w: '4px',
        backgroundImage:
          'linear-gradient(to bottom, rgba(102, 102, 255, 0.8), rgba(255, 102, 173, 1))',
      }}
    >
      <Flex justify="space-between">
        <Box maxH="35px" maxW="150px">
          {typeof mainImg === 'string' ? (
            <Image
              alt="banner logo"
              src={mainImg}
              {...mainImgProps}
              objectFit="contain"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 'auto',
              }}
            />
          ) : (
            <Box textStyle="h3" fontWeight="bold" color="brand-primary">
              {mainImg}
            </Box>
          )}
        </Box>
        <Icon as={Close} width={6} height={6} onClick={onClose} />
      </Flex>
      <Box flexGrow={1} />
      <Box>
        <Box
          backgroundImage="linear-gradient(to right, rgba(102, 102, 255, 0.8), rgba(255, 102, 173, 1))"
          textStyle={smallText ? 'bodySmall' : 'bodySmallBold'}
          backgroundClip="text"
          color="transparent"
          sx={{
            '-webkit-text-stroke': '0.001px black',
          }}
        >
          {text}
        </Box>
      </Box>
      <Flex mt={0.5} justify="space-between" alignItems="center">
        <Text
          textStyle={smallText ? 'caption' : 'bodySmall'}
          color="text.light"
          flexShrink="1"
          noOfLines={2}
        >
          {secondaryText}
        </Text>
        <NextLink href={link} passHref>
          <Link
            mt="auto"
            textStyle="textLink"
            isExternal={isLinkExternal}
            color="text.link"
            flexShrink="0"
          >
            {linkText}
          </Link>
        </NextLink>
      </Flex>
    </Card>
  );
}

export default UpdateBanner;
