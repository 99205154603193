import type { BoxProps, ChakraProps } from '@chakra-ui/react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { SlideIndicator } from '@ui/components/SlideIndicator';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import React, { useEffect, useState } from 'react';
import { Autoplay } from 'swiper';
import type { Swiper as SwiperClass } from 'swiper/types';

import { RenderInDesktop, RenderInMobile } from '../MobileOrDesktop';
import { SwiperSlide, SwiperWithScale } from '../Swiper';
import type { UpdateBannerType } from './types';
import UpdateBanner from './UpdateBanner';
import { useUpdateBannersFromContentful } from './UpdateBannerContentfulProvider';
import UpdateBannerDesktop from './UpdateBannerDesktop';

type UpdateBannersProps = BoxProps;

function UpdateBanners({ ...boxProps }: UpdateBannersProps) {
  const banners = useUpdateBannersFromContentful();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [shownBanners, setShownBanners] = useState<UpdateBannerType[]>([]);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const { data: vnoPrice } = useVnoUsdValue(1);

  const slidesPerView = useBreakpointValue({ base: 1, desktop: 1 });
  const swiperPosition: ChakraProps['position'] = {
    base: 'absolute',
    desktop: 'static',
  };

  const hideBanner = (id: string) => {
    setShownBanners((shownBanners) => shownBanners.filter((b) => b.id !== id));
    localStorage.setItem(`${id}-banner-hidden`, 'true');
  };

  useEffect(() => {
    const shownBanners = banners
      .filter((b) => 'true' !== localStorage.getItem(`${b.id}-banner-hidden`))
      .map((sb) => ({
        ...sb,
        secondaryText: sb.secondaryText.replace(
          '{vnoPrice}',
          (vnoPrice || 0)?.toFixed(2),
        ),
      }));
    setShownBanners(shownBanners);
  }, [banners, vnoPrice]);

  return (
    <Box
      position="relative"
      zIndex={1}
      maxW="920px"
      mx="auto"
      {...boxProps}
      h={shownBanners.length ? boxProps.h : 0}
    >
      <SwiperWithScale
        autoplay={{ delay: 5000, disableOnInteraction: true }}
        position={swiperPosition}
        scaleEnabled={false}
        grabCursor={false}
        modules={[Autoplay]}
        slidesPerView={slidesPerView}
        onSlideChange={({ realIndex }) => {
          setSelectedSlide(realIndex);
        }}
        onSwiper={setSwiper}
        h={shownBanners.length ? '146px' : 0}
        cursor={shownBanners.length >= 2 ? 'grab' : 'auto'}
      >
        {shownBanners.map((sb) => (
          <SwiperSlide key={sb.id}>
            <RenderInMobile>
              <UpdateBanner onClose={() => hideBanner(sb.id)} {...sb} />
            </RenderInMobile>
            <RenderInDesktop>
              <UpdateBannerDesktop
                onClose={() => hideBanner(sb.id)}
                {...sb}
                mainImgProps={sb.mainImgPropsDesktop ?? sb.mainImgProps}
              />
            </RenderInDesktop>
          </SwiperSlide>
        ))}
      </SwiperWithScale>
      <SlideIndicator
        total={shownBanners.length}
        index={selectedSlide}
        onChange={(index) => {
          swiper?.slideTo(index);
        }}
        // occupy the place to avoid UI flickering
        opacity={shownBanners.length >= 2 ? 1 : 0}
        position={swiperPosition}
        top="144px"
        left="0"
        right="0"
      />
    </Box>
  );
}

export default UpdateBanners;
