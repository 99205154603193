import type { ApyInfoProps } from './ApyInfo';
import ApyInfo from './ApyInfo';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from './ModalOrDrawer';

type StakingInfoDrawerProps = ApyInfoProps & {
  close: () => void;
  isOpen: boolean;
};

const StakingInfoDrawer = ({
  close,
  isOpen,
  ...apyInfoProps
}: StakingInfoDrawerProps) => {
  return (
    <ModalOrDrawer
      placement="bottom"
      size="lg"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => close()}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <ModalOrDrawerHeader>Real Staking APY info</ModalOrDrawerHeader>
        <ModalOrDrawerBody>
          <ApyInfo {...apyInfoProps} mb="150px" />
        </ModalOrDrawerBody>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export default StakingInfoDrawer;
