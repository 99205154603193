import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import NextLink from '@ui/overrides/next/link';
import { Close } from '@veno-app/icons';
import React from 'react';

import { Card } from '../Card';
import type { UpdateBannerProps } from './types';

function UpdateBannerDesktop({
  mainImg,
  mainImgProps,
  text,
  secondaryText,
  link,
  isLinkExternal,
  backgroundSize,
  bgImage,
  linkText,
  onClose,
}: UpdateBannerProps) {
  return (
    <Card
      h="116px"
      flexShrink={0}
      mx={6}
      p={2}
      bgPos="calc(100% - 100px)"
      bgImage={bgImage}
      backgroundSize={backgroundSize}
      backgroundImage={bgImage}
      backgroundRepeat="no-repeat"
      position="relative"
      _before={{
        roundedLeft: '5px',
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        w: '4px',
        backgroundImage:
          'linear-gradient(to bottom, rgba(102, 102, 255, 0.8), rgba(255, 102, 173, 1))',
      }}
    >
      <Flex h="full">
        <Flex pt={1} px={2}>
          <Flex
            flexDir="column"
            justify="space-between"
            gap={2}
            pr={6}
            mr={6}
            borderRightWidth="1px"
            borderColor="brand.transparentGrey"
            pb={2}
          >
            {typeof mainImg === 'string' ? (
              <Flex minH="30px" maxH="50px" maxW="150px">
                <Image
                  alt="banner logo"
                  src={mainImg}
                  {...mainImgProps}
                  objectFit="contain"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    margin: 'auto',
                  }}
                />
              </Flex>
            ) : (
              <Box textStyle="h3" fontWeight="bold" color="brand-primary">
                {mainImg}
              </Box>
            )}
            <Box>
              <NextLink href={link} passHref>
                <Button
                  size="sm"
                  target={isLinkExternal ? '_blank' : undefined}
                  as="a"
                  variant="primary"
                >
                  {linkText}
                </Button>
              </NextLink>
            </Box>
          </Flex>
          <Flex flexDir="column" gap={2.5} justify="center">
            <Box>
              <Box
                backgroundImage="linear-gradient(to right, rgba(102, 102, 255, 0.8), rgba(255, 102, 173, 1))"
                textStyle="h4Bold"
                backgroundClip="text"
                color="transparent"
                sx={{
                  '-webkit-text-stroke': '0.001px black',
                }}
              >
                {text}
              </Box>
            </Box>
            <Flex mt={0.5} justify="space-between">
              <Text textStyle="body" color="text.light">
                {secondaryText}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box ml="auto">
          <Icon
            cursor="pointer"
            as={Close}
            width={6}
            height={6}
            onClick={onClose}
          />
        </Box>
      </Flex>
    </Card>
  );
}

export default UpdateBannerDesktop;
