//docs.google.com/spreadsheets/d/1sagvdvWwY8frTCMy9whR1fF-E359DvviHG0KDHlKoZA
const PER_WEEK = 504;
const PER_YEAR = 26280;
const MANAGEMENT_FEE = 0.1;

const aprToGrossApy = <T extends number | undefined>(sevenDayApr: T): T => {
  if (sevenDayApr === undefined) return sevenDayApr;
  const netPerPeriod = Math.pow(1 + (sevenDayApr * 7) / 365, 1 / PER_WEEK) - 1;
  const grossPerPeriod = netPerPeriod / (1 - MANAGEMENT_FEE);
  const feePercentPerPeriod = grossPerPeriod * MANAGEMENT_FEE;
  const netApy = Math.pow(1 + netPerPeriod, PER_YEAR) - 1;
  const feeApyPerYear =
    ((1 - Math.pow(1 + feePercentPerPeriod, PER_YEAR)) / -feePercentPerPeriod) *
    feePercentPerPeriod;
  const grossApy = netApy + feeApyPerYear;
  return grossApy as T;
};

export default aprToGrossApy;
