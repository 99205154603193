import type { Banner } from '@ui/queries/contentfulBanners';
import type { ReactNode } from 'react';
import React, { createContext, useContext, useMemo } from 'react';

import type { UpdateBannerType } from './types';

const UpdateBannerContentfulContext = createContext<Array<UpdateBannerType>>(
  [],
);

/**
 * A provider to avoid props drilling, it transforms to ui model + provides the update banners from
 * Contentful, they are generated at build time.
 */
function UpdateBannerContentfulProvider({
  children,
  banners,
}: {
  children: ReactNode;
  banners: Banner[];
}) {
  const value: UpdateBannerType[] = useMemo(
    () =>
      banners.map((banner) => ({
        id: banner.subtitle,
        mainImg: `${banner.logo.url}?w=200`,
        text: banner.title,
        secondaryText: banner.subtitle,
        link: banner.url,
        linkText: banner.cta,
        isLinkExternal: banner.isLinkExternal,
        bgImage: '/images/pattern-4-bg.png',
        backgroundSize: '236px 116px',
        mainImgProps: { width: 100 },
      })),
    [banners],
  );

  return (
    <UpdateBannerContentfulContext.Provider value={value}>
      {children}
    </UpdateBannerContentfulContext.Provider>
  );
}

export const useUpdateBannersFromContentful = (): UpdateBannerType[] => {
  return useContext(UpdateBannerContentfulContext);
};

export default UpdateBannerContentfulProvider;
