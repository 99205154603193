import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';

export type SlideIndicatorProps = Omit<FlexProps, 'onChange'> & {
  total: number;
  index: number;
  onChange: (index: number) => void;
};
export const SlideIndicator = ({
  total,
  index,
  onChange,
  ...props
}: SlideIndicatorProps) => {
  return (
    <Flex justifyContent="center" alignItems="center" gap="8px" {...props}>
      {[...Array(total)].map((_, i) => {
        const isActive = i === index;
        return (
          <Box
            key={i}
            cursor="pointer"
            rounded="full"
            h={isActive ? '8px' : '6px'}
            w={isActive ? '8px' : '6px'}
            bgColor={isActive ? 'bg.button' : 'shadow'}
            onClick={() => {
              onChange(i);
            }}
          />
        );
      })}
    </Flex>
  );
};
