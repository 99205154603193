import { useQuery } from '@tanstack/react-query';
import { fetchFromContentful } from '@ui/utils/contentful';

export const useContentfulBanners = () => {
  return useQuery({
    queryKey: ['contentfulBanners'],
    queryFn: () => fetchContentfulBanners(false),
    // enough to refetch on page load
    staleTime: Infinity,
  }).data;
};

/**
 * Fetches active banners from Contentful.
 *
 * @param preview fetch preview data
 * @returns a list of active banners.
 */
export const fetchContentfulBanners = async (
  preview = false,
): Promise<Banner[]> => {
  const data = await fetchFromContentful<BannerCollectionResponse>(
    bannerQuery,
    undefined,
    preview,
  );
  return data.bannerCollection.items.sort((a, b) => b.priority - a.priority);
};

const bannerQuery = `
query GetBanners {
  bannerCollection(where: {active: true}) {
    items {
      logo {
        sys {
          id
        }
        url
        title
      }
      title
      subtitle
      url
      active
      cta
      isLinkExternal
      priority
    }
  }
}
`;

export type Banner = {
  logo: {
    sys: { id: string };
    url: string;
    title: string;
  };
  title: string;
  subtitle: string;
  url: string;
  active: boolean;
  isLinkExternal: boolean;
  cta: string;
  priority: number;
};

type BannerCollectionResponse = {
  bannerCollection: {
    items: Banner[];
  };
};
