import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import { useGlobalCoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import React from 'react';

import Info from './Info';

export type ApyInfoProps = {
  netApy: string;
  grossApy: string;
} & FlexProps;

function ApyInfo({ netApy, grossApy, ...flexProps }: ApyInfoProps) {
  const t = useTranslations();
  const { showBreakdown, fee } = useApyInfo();
  return (
    <Flex
      gap={4}
      flexDir="column"
      px={{ base: 0, desktop: 8 }}
      py={{ base: 0, desktop: 4 }}
      minW={{ base: 'full', desktop: '407px' }}
      {...flexProps}
    >
      <Info title={t('Net APY')} value={netApy} />
      <Info title={t('Gross APY')} value={grossApy} />
      <Info
        title={t('Management Fees')}
        value={fee}
        convertedValue={
          showBreakdown ? (
            <Flex flexDir="column">
              <Text>Treasury 50%</Text>
              <Text>Reservoir 50%</Text>
            </Flex>
          ) : null
        }
      />
    </Flex>
  );
}

const useApyInfo = () => {
  const coinMode = useGlobalCoinMode();
  // in case we want to show it for ETH in the future, the actual numbers are here
  if (coinMode === 'eth') return { showBreakdown: false, fee: '12%' };
  return { showBreakdown: true, fee: '10%' };
};

export default ApyInfo;
