const getContentfulHeader = (preview: boolean) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${
      preview
        ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
        : process.env.CONTENTFUL_ACCESS_TOKEN
    }`,
  };
};

type GraphQLResponse<T> = {
  data: T;
  errors?: { message: string }[];
};

/**
 * cache to reduce API calls in static generation
 */
const cache: { [key: string]: unknown } = {};

/**
 * fetches data from contentful. will cache the response based on all params to reduce API calls
 *
 * @param query
 * @param variables
 * @param preview
 * @returns
 */
export async function fetchFromContentful<T>(
  query: string,
  variables?: { [key: string]: unknown },
  preview: boolean = false,
): Promise<T> {
  const cacheKey = JSON.stringify({ query, variables, preview });
  if (cache[cacheKey]) {
    return cache[cacheKey] as T;
  }

  const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: getContentfulHeader(preview),
    body: JSON.stringify({ query, variables }),
  });
  const json: GraphQLResponse<T> = await response.json();
  if (json.errors) {
    const message = json.errors.map((error) => error.message).join(', ');
    throw new Error(`GraphQL Error: ${message}`);
  }

  cache[cacheKey] = json.data;
  return json.data;
}
